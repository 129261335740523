<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <PxHeader :activeSearchMobile="false" />
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section
    class="bg-agrogo-contactus sizeMinAllView"
    :style="styleObject(record?.img_head_url)"
    v-else
  >
    <publish-button />
    <div
      class="flex items-center bg-agrogo-yellow-1 h-14 pl-6 md:h-16 md:pl-40"
    >
      <div
        class="font-nexa font-black text-base text-agrogo-green-2 text-left md:text-2xl"
      >
        <div class="style-info" v-html="record.title" />
      </div>
    </div>
    <div>
      <div class="container-bg-login-desktop">
        <div class="bg-agrogo-green-2 bg-agrogo-green-2-transparent max-h-470">
          <div class="md:hidden">
            <img class="" :src="loadImg(record.img_head_url)" alt="" />
          </div>
          <div class="container pt-6 pb-8 px-4 md:pt-12 md:pb-28 md:px-10">
            <div v-for="(item, index) in record.page_content" :key="index">
              <p
                class="font-nexa font-normal text-agrogo-gray-1 text-base md:text-xl text-left mb-5"
                v-if="item.name_id == 'CONTACT_P_1'"
                v-html="item.content"
              />
              <p
                class="font-nexa font-normal text-base md:text-2xl text-white text-left mb-8 w-2/3 md:mb-16"
                v-if="item.name_id == 'CONTACT_P_2'"
                v-html="item.content"
              />
            </div>
            <div
              class="grid grid-cols-1 md:grid-cols-2 sm:ml-0 gap-x-2 place-items-start"
            >
              <contact-email :item="dataEmail" />
              <contact-phone :item="dataTelephone" :message="message" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
import metaMixin from "@/mixins/metaMixin";
// Components
import PxHeader from "@/components/header/PxHeader";
import PublishButton from "@/components/publishButton/PublishButton";
import ContactEmail from "./components/ContactEmail";
import ContactPhone from "./components/ContactPhone";

export default {
  mixins: [metaMixin],
  metaInfo() {
    const me = this;
    return me.dataMeta;
  },
  name: "ContactUs",
  inject: ["provider"],
  components: {
    PxHeader,
    PublishButton,
    ContactEmail,
    ContactPhone,
  },
  data() {
    return {
      /**
       * Variable that allows access to the methods of the provider publishFarmRepository
       * @type {Object}
       */
      repository: this.provider.publishFarmRepository,
      /**
       * @type {String |  Object>}
       */
      email: null,
      telephone: null,
      dataEmail: null,
      dataTelephone: null,
      message: null,
      /**
       * Variable that saves the data of the questions obtained by the agrogo api
       * @type {Array}
       */
      record: [],
      isBusy: true,
    };
  },
  methods: {
    /**
     * Query the agrogo api and obtain the information of the questions.
     * @returns  {void}
     */
    async getContactUs() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllNames("CONTACTANOS", dataAditional);

        if (data?.metadata) {
          me.meta = data?.metadata;
          me.loadDataMeta();
        }
        me.record = data;
        me.record.page_content.forEach((el) => {
          if (el.name_id == "CONTACT_EMAIL") {
            me.dataEmail = el;
          } else if (el.name_id == "CONTACT_PHONE") {
            me.dataTelephone = el;
          } else if (el.name_id == "CONTACT_MSG") {
            me.message = el.content
              .split("<p>")
              .join(" ")
              .split("</p>")
              .join(" ");
          }
        });
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    loadImg(url) {
      let img = null;
      img = url
        ? `${API_ROUTES.image.get}${url}`
        : require("@/assets/images/paisajecontactusdesktop.jpg");
      return img;
    },
    styleObject(url) {
      let img = null;
      img = url
        ? `${API_ROUTES.image.get}${url}`
        : require("@/assets/images/paisajecontactusdesktop.jpg");
      return { backgroundImage: `url(${img})` };
    },
  },

  async mounted() {
    const me = this;
    window.scroll(0, 0);
    me.getContactUs();
  },
};
</script>

<style>
.input-size {
  width: 222px;
}
</style>
